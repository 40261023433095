import React, { Component } from "react"
import { navigate } from "gatsby"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class ContactForm extends Component {
  state = {
    name: "",
    email: "",
    comment: "",
    confirm: false,
  }
  saveToState = e => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value
    this.setState({ [e.target.name]: value })
  }
  render() {
    return (
      <form
        noValidate
        name="contact"
        action="/thanks/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={async e => {
          e.preventDefault()
          const form = e.target
          fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({
              "form-name": form.getAttribute("name"),
              ...this.state,
            }),
          })
            .then(() => navigate(form.getAttribute("action")))
            .catch(error => alert(error))
          this.setState({
            name: "",
            email: "",
            comment: "",
            confirm: false,
          })
        }}
      >
        <fieldset>
          <label htmlFor="name">Name</label>
          <input
            type="text"
            name="name"
            placeholder="name"
            value={this.state.name}
            onChange={this.saveToState}
          />
          <label htmlFor="email">Email</label>
          <input
            type="email"
            name="email"
            placeholder="email"
            value={this.state.email}
            onChange={this.saveToState}
          />
          <label htmlFor="comment">Comment</label>
          <textarea
            type="text"
            name="comment"
            placeholder="Say what you wanna say.."
            value={this.state.comment}
            onChange={this.saveToState}
          />
          <div>
            <input
              type="checkbox"
              name="confirm"
              checked={this.state.confirm}
              onChange={this.saveToState}
            />
            <label className="label-inline" htmlFor="confirm">
              Send a copy to yourself
            </label>
          </div>
          <div>
            <button type="submit" className="button-primary">
              Send!
            </button>
          </div>
        </fieldset>
      </form>
    )
  }
}

export default ContactForm
