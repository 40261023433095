import React from "react"

import SEO from "../components/seo"
import ContactForm from "../components/ContactForm"

const ContactPage = () => (
  <>
    <SEO title="Contact" />
    <h1>Send me an email!</h1>
    <ContactForm />
  </>
)

export default ContactPage
